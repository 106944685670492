import request from "@/utils/request";

// 日语APP约课列表
export function AppointmentList(params) {
    return request({
        url: '/admin/korean_v3/appointmentList',
        method: 'get',
        params
    })
}

// 日语APP约课类型
export function AppointmentType(params) {
    return request({
        url: '/admin/korean_v3/appointmentType',
        method: 'get',
        params
    })
}


// 编辑日语APP约课
export function AppointmentEdit(data) {
    return request({
        url: '/admin/korean_v3/appointmentEdit',
        method: 'put',
        data
    })
}

// 约课上传COS签名
export function getAppointmentCosToken(params) {
    return request({
        url: '/admin/korean_v3/getAppointmentCosToken',
        method: 'get',
        params
    })
}
